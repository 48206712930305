<template>
  <div class="sideBar px-3 box-shadow bg-white">
    <button @click="resetFilter" class="btn primary-button text-white py-1">
      Clear all filters
    </button>
    <h6 class="my-3 secondaryColor border-bottom pb-3">Filters</h6>
    <div class="mt-1">
        <!-- shifts collection Section -->
          <!-- <div class="border-bottom border-2 py-2">
            <h5 class="font-size-14 fw-medium mb-1 fw-bold">Shifts Collection</h5>
            <div>
              <select
                v-model="sideBarData.unAssignedRoleShifts"
                class="form-select mt-2 bg-light border-0 shadow-none" >
                <option value="All">Show All</option>
                <option value="unAssignedRoleShifts">Un-assigned Role Shifts</option>
              </select>
            </div>
          </div> -->
        <!--end of shifts colection Section -->
        <div
            class="py-1 border-bottom"
            >
            <h5 class="f-14 fw-medium mb-1 fw-bold py-1">Select Range</h5>
            <date-range-picker
                class="bg-light w-100"
                ref="picker"
                :opens="'right'"
                :locale-data="{ format: 'mm/dd/yyyy' }"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="true"
                :showDropdowns="true"
                :autoApply="false"
                v-model="date_range"
            >
                <template v-slot:input="picker" style="min-width: 350px">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
            </date-range-picker>
        </div>

      <!-- location filter -->

        <div
          class="card shadow-none border-bottom pb-2 py-3"
          >
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-location
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-location"
          >
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Location</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>

          <b-collapse
            id="faqs-gen-location"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <select
              v-model="sideBarData.location"
              class="form-select mt-2 bg-light border-0 shadow-none"
              >
              <option value="All" selected>Show All</option>
              <option
              v-for="(location, index) in locations"
              :value="location"
              :key="index"
              >
              {{ location.name }}
              </option>
          </select>
          </b-collapse>
        </div>

        <div
          class="card shadow-none border-bottom pb-2" v-if="$route.name != 'shifts-roster'"
          >
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-type
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-type"
          >
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Shift Type</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>

          <b-collapse
            id="faqs-gen-type"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <select
              v-model="sideBarData.shift_type"
              class="form-select mt-2 bg-light border-0 shadow-none"
              >
              <option value="All">Show All</option>
              <option
              v-for="(shift_type, index) in shift_types"
              :value="shift_type"
              :key="index"
              >
              {{ shift_type }}
              </option>
          </select>
          </b-collapse>
        </div>

        <!-- <div
          class="card shadow-none border-bottom pb-2"
          >
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-roles
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-roles"
          >
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Roles</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>
          <b-collapse
            id="faqs-gen-roles"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <vue-multiselect
              v-model="sideBarData.role"
              hide-selected
              :load-more="loadMoreAreas"
              :loading="roles.loading"
              :options="roles.data"
              @on-open="fetchAreas"
              @on-reaching-end="fetchAreasOnReachingEnd"
              :id="'roles'"
              label="name"
            >
            </vue-multiselect>
          </b-collapse>
        </div> -->

        <!-- <div class="card shadow-none border-bottom pb-2"  v-if="isRestaurantOwner() && $route.name != 'shifts-roster'">
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-Manager
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-Manager"
          >
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Filter By Manager</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>

          <b-collapse
            id="faqs-Manager"
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <div class="y_scroll">
                <select
                  v-model="sideBarData.created_by" 
                  id="emp_job_type" 
                  class="form-select bg-light border-0 shadow-none mt-2"
                  >
                  <option value="All">Show All</option>
                  <option :value="manager.id" v-for="(manager,index) in managers" :key="index">{{manager.name}}</option>
                </select>
            </div>
          </b-collapse>
        </div> -->
    </div>
  </div>
</template>
<script>
import VueMultiselect from "@/components/general/vue-multiselect";
import _ from "lodash";

import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const filter_Prototype = {
  location: null,
  shift_name: null,
  shift_type : null,
  interval_name : null,
  area: {},
  created_by: null,
  role: {},
  unAssignedRoleShifts : false,
};
export default {
  components: {
    DateRangePicker,
  },

  data() {
    return {
      areas: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      employees: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      roles: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      params: {},
      managers: [],
      locations: [],
      location: "All",
      shift_names: [],
      shift_types: ["Premium", "Non_Premium"],
      intervals: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      date_range: {
        startDate: null,
        endDate: null,
      },
      sideBarData: {
        ...filter_Prototype,
      },
    };
  },
  computed: {
    loadMoreAreas() {
      return !!this.areas.nextPageUrl;
    },
  },
  watch: {
    sideBarData: {
      handler: function (newData) {
        this.$emit("filterData", newData);
      },
      deep: true,
    },

    "date_range.endDate": {
      handler: function (new_data, old_data) {
        if (new_data) {
          this.$emit("filterData", {
            startDate: this.formatDateYMD(this.date_range.startDate),
            endDate: this.formatDateYMD(this.date_range.endDate),
          });
        }
      },
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },

  methods: {
    // fetchManagers() {
    //     this.$axios.get("/employee/manager/index" ,{paginated:false}).then((response) => {
    //         let data = response.data.payload;
    //         this.managers = data;
    //     }).catch(error => {
    //         this.handleErrorResponse(error.response, "error");
    //     }).finally(function () {
    //     });
    // },
    resetFilter() {
      this.sideBarData = {
        ...filter_Prototype,
      };
      this.date_range = {
        startDate: null,
        endDate: null,
      };
      this.$emit("filterData", { clearFilters: true });
    },
    
    fetchAreas() {
      let url = "/restaurant/area/role/index";
      if (this.areas.nextPageUrl) {
        url = this.areas.nextPageUrl;
      }
      this.$axios
        .get(url, { params: this.params })
        .then((response) => {
          let result = response.data.payload.paginated;
          if (url == "/restaurant/area/role/index") {
            this.areas.data = [];
            this.roles.data = [];
          }
          this.areas.data.push(...result.data);
          //add roles
          result.data.forEach((element) => {
            this.roles.data.push(...element.area_role);
          });

          this.areas.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    resetData() {
      this.areas.data = [];
      this.areas.nextPageUrl = null;
    },

    fetchAreasOnReachingEnd() {
      this.fetchAreas();
    },

    fetchLocations() {
      this.$axios
        .get("/locations", { params: { paginated: false } })
        .then((response) => {
          this.locations = response.data.payload;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(function () {});
    },
    getShiftNames() {
      this.$axios.get('shift/name/index' , {params: {pagination : 0}}).then((response) => {
          let data = response.data.payload.paginated;
          this.shift_names = data;
      }).catch((error) => {
      this.handleErrorResponse(error.response, "error");
      });
    },
  },
  mounted() {
    this.fetchLocations();
    // this.fetchManagers();
    this.getShiftNames();
    // this.getfilterLocations();
  },
};
</script>