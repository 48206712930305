<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import MagicTable from "@/components/MagicTable/MagicTable";
    import Swal from 'sweetalert2';
    import assignShiftModal from "./components/assignShiftSlot.vue";
    import sidebar from "./components/sidebar.vue";
    import $ from "jquery";
    import excelParser from "@/mixins/excel-parser.js";

    /**
     * Add-product component
     */
    export default {
        page: {
            title: "Shifts",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
            MagicTable,
            assignShiftModal,
            sidebar,
        },
        data() {
            return {
                isClick: false,
                title: "Shifts",
                fetch_url: "/shift/index",
                items: [
                    {
                        text: "Shifts",
                    },
                    {
                        text: "Shift Listing",
                        active: true,
                    },
                ],
                shifts: [],
                clone_shifts: null,
                arearole: null,
                location: "All",
                created_by: null,
                startDate: null,
                endDate: null,
                currentDate: new Date(),
                firstDate: null,
                lastDate: null,
                slider: null,
                cloneShiftsModel:false,
                duplicateFrom:null,
                duplicateTo:null,
                shift_id:null,
                renderTable:true,

                selected: [],
                selectsArray: [],
                // locations:[],
                assign_shift : {},
                
                extraFilters:{
                }
            };
        },
        computed: {
        selectAll: {
            get: function () {
                    // return this.shifts ? this.selected.length == this.shifts.length : false;
                    return false;
            },
            set (value) {
                this.selectsArray = this.shifts;
                this.selected = []
                if (value) {
                    this.selectsArray.forEach((select) => {
                        this.selected.push(select.id)
                    })
                }
            }
        }, // END selectAll
        },
        middleware: "authentication",

        methods: {
            listenFromSideBar(newData) {
                 if (newData?.location) {
                    if(newData?.location == "All"){
                        this.extraFilters.location_id = null;
                        this.location = "All";
                        this.slider = null;
                    }else{
                        this.extraFilters.location_id = newData.location.id;
                        this.location = newData.location;
                    }
                }
                if (newData?.shift_name) {
                    if (newData?.shift_name == "All") {
                        this.extraFilters.name = null;
                    }else{
                        this.extraFilters.name = newData?.shift_name;
                    }
                }

                if (newData?.shift_type) {
                    if (newData?.shift_type == "All") {
                        this.extraFilters.shift_type = null;
                    }else{
                        this.extraFilters.shift_type = newData?.shift_type;
                    }
                }
                
                if (newData?.created_by) {
                    if(newData?.created_by == "All"){
                        this.extraFilters.created_by = null;
                    }else{
                        this.extraFilters.created_by = newData.created_by;
                    }
                }

                if (newData?.interval) {
                    if(newData?.interval == "All"){
                        this.extraFilters.interval = null;
                    }else{
                        this.extraFilters.interval = newData.interval;
                    }
                }

                if (newData?.area) {
                    this.extraFilters.area_id = newData?.area?.id;
                }

                if (newData?.endDate) {
                  this.extraFilters.from = newData.startDate;
                  this.extraFilters.to = newData.endDate;
                }
                if (newData?.unAssignedRoleShifts) {
                    if(newData?.unAssignedRoleShifts == "All"){
                        this.extraFilters.unAssignedRoleShifts = null;
                    }else{
                        this.extraFilters.unAssignedRoleShifts = newData.unAssignedRoleShifts;
                    }
                }
                
                if (newData?.role) {
                    this.extraFilters.area_role_id = newData?.role?.id;
                }

                if (newData?.clearFilters) {
                    this.extraFilters = {};
                    this.location = "All";
                    this.slider = null;
                }

                if (newData != null) {
                    this.renderTable=false;
                    this.$nextTick(() => {
                        this.renderTable=true
                    });
                }
            },
            assignShift(shift)
            {
                this.assign_shift = shift;
            },
            deleteSelected(){
                if(this.selected.length == 0){
                    Swal.fire("Error!", 'No Record Selected', "error");
                }else{
                    // console.log(this.selected);
                    Swal.fire({
                        title: "Are you sure?",
                        text: "This action can not be revert.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#70C213",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, Delete it",
                    }).then((result) => {
                        if (result.value) {
                            this.$axios.post('/shift/delete', {shift_id: this.selected}).then(response => {
                                if(response.data.status == true){
                                    for(var i = 0 ; i < this.selected.length; i++){
                                        this.shifts = this.shifts.filter((item) => (item.id != this.selected[i]));
                                    }
                                Swal.fire("Deleted!", response.data.message, "success");
                                this.selected.length = 0;
                                }
                            }).catch(error => {
                                this.handleErrorResponse(error.response, "error");
                            }).finally(function () {
                            });
                        }
                    });   
                }
            },
            fetchShiftsByWeek(direction) {
                this.slider = direction;
                this.weekSlider(direction);
                this.extraFilters.from = this.startDate;
                this.extraFilters.to = this.endDate;
                this.$axios.get("/shift/index", { params: this.extraFilters}).then((response) => {
                    let data = response.data.payload;
                    this.shifts = data.paginated.data;
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

            weekSlider(direction) {
                let dayNumber = this.getDayNumberByName(this.location.start_day);
                this.currentDate = new Date(this.currentDate);
                var first1 = this.currentDate.getDate() - this.currentDate.getDay() + dayNumber;

                var last1 = first1 + 6; // last day is the first day + 6
                this.firstDate = new Date(this.currentDate.setDate(first1));
                this.lastDate = new Date(this.currentDate.setDate(last1));
                if (direction == "current") {
                    this.currentDate = new Date();
                }
                if (direction == "next") {
                    this.currentDate = this.lastDate;
                }
                if (direction == "previous") {
                    this.currentDate = this.firstDate.setDate(this.firstDate.getDate() - 6);
                }
                var curr = new Date(this.currentDate); // get current date
                var first = curr.getDate() - curr.getDay();

                var last = first + 6; // last day is the first day + 6
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));

                this.startDate = this.formatDateYMD(firstday);
                this.endDate = this.formatDateYMD(lastday);
            },
            updateRecords(records) {
                this.shifts = records;
                this.clone_shifts = records;
            },

            rolesToString(areaRoles) {
                let name_array = areaRoles.map((role) => {
                    return role.name;
                });
                let string = name_array.toString();

                return string;
            },
            cloneShifts(shift) {
                if (shift != null) {
                    this.shifts = [];
                    this.shifts.push(shift);
                }
                this.$store.state.shift.shift = this.shifts;
                this.$router.push("/edit-clone-shift");
            },

            cloneShiftsToDate() {
                let self = this;
                    if (self.processing) {
                        return;
                    }
                    self.processing = true;

                    self.$axios.post('/shift/store-cloned-shifts-to-date', {
                        duplicateFrom:this.duplicateFrom,
                        duplicateTo:this.duplicateTo,
                        shift_id:this.shift_id,

                }).then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                        this.renderTable=false
                            self.cloneShiftsModel=false;
                            this.$nextTick(() => {
                        this.renderTable=true
                            });
                    
                                     
                })
                .catch(error => {
                    self.cloneShiftsModel=false;
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(() => {
                    self.processing = false;
                    this.duplicateFrom = null;
                    this.duplicateTo = null;
                });
            },

            // print(){
            //     const section = $("#section");
            //     const modalBody = $("#model-content").detach();
            //     const content = $("#place_content").detach();
            //     section.append(modalBody);
            //     window.print();
            //     section.empty();
            //     section.append(content);
            //     $('#listing_parent_card').append(modalBody);
            // },

            exportCSV()
            {
                this.extraFilters.length  = this.$refs.shift_listing_tbl.pagination.total;
                this.$axios.get('/shift/index',{params: this.extraFilters})
                .then(response => {
                    let shiftsListing= response.data.payload.paginated.data;
                    let buildData = shiftsListing.map((item) => {
                        return {
                            'Shift Name' : this.getSafe(() => item.name),
                            'From' : this.getSafe(() => this.DateWithMonthName(item.shift_time_slots[0].from)),
                            'To' : this.getSafe(() => this.DateWithMonthName(item.shift_time_slots[0].to)),
                            'Interval' : this.getSafe(() => item.interval),
                            'Role/Position' : this.getSafe(() =>  this.rolesToString(item.area_roles)),
                            'Location' : this.getSafe(() =>  item.location.name),
                            'Created By' : this.getSafe(() => item.creator.name),
                        }
                    })
                    excelParser.methods.exportDataFromJSON(buildData , 'shifts' , null)
                }).catch(error => {
                    // this.handleErrorResponse(error.response, "error");
                    console.log(error);
                })

            }
        },
    };
</script>

<template>
    <Layout :dynamicClass="'container-fluid'">
        <div id="section">
            <div id="place_content" class="row" >
                <div  id="modal-parent">
                    <div class="row">
                        <div
                            class="col-xl-2 ps-0 position-relative transition_class"
                            :class="{ fullWidth: isClick }" >
                            <sidebar ref="sideBar" @filterData="listenFromSideBar" />
                            <i class="fas position-absolute top-0 end-0 px-4 f-16 primaryColor pointer"
                                @click="isClick = !isClick"
                                :class="isClick == false ? 'fa-times' : 'fa-bars'" >
                            </i>
                        </div>
                        
                        <div class="col-xl-10" :class="{ 'col-xl-12': isClick }" >
                            <PageHeader :items="items" :title="title" />
                            <div class="card" v-if="location!='All'">
                                <div class="card-body">
                                    <div class="row align-items-center radius-10">
                                        <div class="col-md-3 mx-auto mt-3">
                                            <ul class="pagination" >
                                                <li class="paginate_button page-item previous">
                                                    <button type="button" @click="fetchShiftsByWeek('previous')" class="page-link"><i class="fas fa-arrow-left"></i></button>
                                                </li>
                                                <li class="mx-2 f-10 text-center">
                                                    <span class="f-12 btn pb-0 pt-0" @click="fetchShiftsByWeek('current')">Current Week</span><br />
                                                    <span v-if="startDate!=null">{{startDate}} to {{endDate}}</span>
                                                </li>

                                                <li class="paginate_button page-item next">
                                                    <button type="button" @click="fetchShiftsByWeek('next')" aria-controls="DataTables_Table_0" data-dt-idx="8" tabindex="0" class="page-link">
                                                        <i class="fas fa-arrow-right"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" >
                                <div class="card-body" id="listing_parent_card">
                                    <div class="text-start">
                                        <div class="mb-3">
                                            <button class="btn gradientColor border-0 radius-20 px-4 btn-secondary" @click="deleteSelected()">Delete Selected</button>
                                            <router-link to="/create-shift" ><b-button class="gradientColor border-0 radius-20 px-4 float-end">Create Shift</b-button></router-link>
                                            <!-- <b-button class="gradientColor border-0 radius-20 px-4 float-end mx-2" @click="print();">Print Shifts</b-button> -->
                                            <b-button class="gradientColor border-0 radius-20 px-4 float-end mx-2" @click="exportCSV();"><i
                                                class="uil uil-export align-middle me-1"
                                            ></i> Export Shifts as CSV</b-button>
                                            <b-button @click="cloneShifts(null)" class="gradientColor mx-2 border-0 radius-20 px-4 float-end" v-if="shifts.length>0 && slider!=null">Clone Shift</b-button>
                                        </div>
                                    </div>
                                    <magic-table id="model-content" v-if="renderTable" :fetch_url_prop="fetch_url" :extraFilters="extraFilters" @recordsUpdated="updateRecords" ref="shift_listing_tbl">
                                        <template v-slot:table>
                                            <div class="row mt-4">
                                                <div class="col-lg-12">
                                                    <div class="table-responsive table-nowrap">
                                                        <div class="mb-4">
                                                            <strong>Note : </strong> <span class="primaryColor"> Cannot clone shifts with unassigned roles. Please assign roles before cloning.</span>
                                                        </div>
                                                        <table class="table mb-0 table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <fieldset id="checkArray">
                                                                        <input type="checkbox" class="form-check-input" v-model="selectAll"/>
                                                                    </fieldset>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Name</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">From</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">To</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Interval</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Shift Type</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Area Role/Position</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Location Name</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Created By</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Created At</th>
                                                                    <th class="f-14 fw-semiBold roboto text-dark">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-if="shifts.length > 0">
                                                                    <tr v-for="(shift,key) in shifts" :key="key" class="align-middle">
                                                                    
                                                                        <td scope="row" class="f-14 roboto pColor">
                                                                            <input type="checkbox" class="form-check-input"  v-model="selected" :value="shift.id" />
                                                                        </td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() =>shift.name)}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() => shift.from)}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() => shift.to)}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() =>shift.interval)}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() => shift.shift_type)}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">
                                                                            <template v-if="shift.shift_roles.length>0">
                                                                            
                                                                                {{ getSafe(() => rolesToString(shift.shift_roles)) }}
                                                                            </template>
                                                                            <template v-else>
                                                                                N/A
                                                                            </template>
                                                                            </td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() =>shift.location.name)}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() =>shift.creator.full_name ?? "N/A")}}</td>
                                                                        <td scope="row" class="f-14 roboto pColor">{{ getSafe(() =>DateWithMonthName(shift.created_at , 'ddd, MMM DD,YYYY hh:mm A'))}}</td>

                                                                        <td class="f-14 roboto align-middle">
                                                                            <div class="action-buttons d-flex justify-content-between">
                                                                                <b-dropdown class="d-inline-block" right variant="white" toggle-class="header-item" menu-class="dropdown-menu-end">
                                                                                    <template v-slot:button-content>
                                                                                        <i class="fas fa-ellipsis-v d-xl-inline-block font-size-15 primaryColor"></i>
                                                                                    </template>
                                                                                    <router-link v-b-tooltip.hover title="Edit" class="dropdown-item" :to="{ name: 'edit_shift', params: { shift_id: shift.id }}">
                                                                                        <i class="uil uil-pen font-size-18 align-middle me-1 text-muted"></i>
                                                                                        <span class="align-middle">Edit</span>
                                                                                    </router-link>
                                                                                    <button class="dropdown-item" @click="deleteSelected(selected=[shift.id])" title="Delete Shift">
                                                                                        <i class="uil uil-trash font-size-18 align-middle me-1 text-muted"></i>
                                                                                        <span class="align-middle">Delete</span>
                                                                                    </button>
                                                                                    <button class="dropdown-item"  v-b-modal.modal-assignShift @click="assignShift(shift)" >
                                                                                        <i class="uil uil-user-check font-size-18 align-middle me-1 text-muted"></i>
                                                                                        <span class="align-middle">Assign Shift</span>
                                                                                    </button>
                                                                                    <button class="dropdown-item" :disabled="shift.area_roles == 0" @click="cloneShiftsModel=true; shift_id = shift.id;" title="CLone Shift">
                                                                                        <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
                                                                                        <span class="align-middle">Clone</span>
                                                                                    </button>
                                                                                </b-dropdown>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </magic-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="cloneShiftsModel"
            title="Select date range to duplicate shifts"
            title-class="text-black font-18"
            body-class="p-3"
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            modal-header-close
            >
        <form id="releaseShift">
            <div class="row">
                <div class="col-md-6 mb-2">
                    <div class="align-items-center d-flex gap-2">
                        
                        <div class="form-field__wrapper w-100">
                            <b-form-group id="example-dateFrom" label="Duplicate Shift From" label-for="dateFrom">
                                <b-form-input id="dateFrom" v-model="duplicateFrom" :min="getCurrentDate()" type="date"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-2">
                    <div class="align-items-center d-flex gap-2">
                        
                        <div class="form-field__wrapper w-100">
                            <b-form-group id="example-dateTo" label="Duplicate Shift To" label-for="dateTo">
                                <b-form-input id="dateTo" v-model="duplicateTo" :min="duplicateFrom" type="date"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="text-end pt-5 mt-3">
            <button type="button"  class="btn btn-outline-pink mx-3" @click="cloneShiftsModel=false">Close</button>
            <b-button @click="cloneShiftsToDate" variant="success" class="ms-1"
                >Duplicate</b-button
            >
            </div>
        </form>
        </b-modal>
        <assignShiftModal ref="assignShift" :shift="assign_shift"> </assignShiftModal>
    </Layout>
</template>
